import React from 'react'
import Layout from '../../../components/layout'
import Nav from '../../../components/nav'
import SEO from '../../../components/seo'

import { ErrorBoundaryWithLogger } from '../../../frontend-lib/Log'

const PrivacyEs = () => {
    const lang = 'es'
    return (
        <ErrorBoundaryWithLogger serverUrl={process.env.GATSBY_SERVER_URL} logName="sitio web">
            <Layout lang={lang}>
                <SEO
                    title={'Optiwe - Política de Privacidad'}
                    description={'Política de privacidad del servicio'}
                    lang={'es'}
                    locales={[
                        { hreflang: 'en', href: 'https://optiwe.com/legal/privacy' },
                        { hreflang: 'es', href: 'https://optiwe.com/es/legal/privacy' },
                        { hreflang: 'x-default', href: 'https://optiwe.com/legal/privacy' }
                    ]}
                />

                <main className="container-fluid ow-banner">
                    {/*
                    ^*********
                    * CABECERA *
                    **********
                    */}
                    <Nav
                        lang={lang}
                        showButtons
                    />

                    <div className="container ow-banner__content">
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12 ow-banner__home-content">
                                <h1>Términos y Condiciones de Optiwe, Inc.</h1>
                                <h2>Efectivo a partir del 1 de mayo de 2020</h2>
                                <p>
                                    Por favor, lea estos Términos detenidamente. Al utilizar Optiwe o registrarse para una cuenta, usted acepta estos Términos, lo que resultará en un acuerdo legal entre usted y Optiwe ("Acuerdo"). Empezaremos con lo básico, incluyendo algunas definiciones que deberían ayudarle a comprender estos Términos. Optiwe ("Optiwe", "nosotros" o "nuestro") es una plataforma de servicio al cliente de inteligencia artificial (la "Aplicación") que le permite mejorar y optimizar las operaciones de servicio al cliente. La Aplicación se ofrece a través de nuestros sitios web, incluyendo https://www.optiwe.com y https://app.optiwe.com, y cualquier otro sitio web, extensión de Chrome o aplicación móvil de propiedad, operado o controlado por nosotros (nos referiremos colectivamente a estos como el "Servicio"). Optiwe es una Corporación de Delaware cuyo nombre legal es Optiwe, Inc. Optiwe tiene empleados, contratistas independientes y representantes ("nuestro Equipo"). Como cliente del Servicio o representante de una entidad que es cliente del Servicio, usted es un "Miembro" según este Acuerdo (o "usted").
                                    Estos Términos Estándar de Uso definen los términos y condiciones bajo los cuales se le permite utilizar el Servicio de acuerdo con el Acuerdo, y cómo trataremos su cuenta mientras sea un Miembro. Si no está de acuerdo con estos Términos, debe interrumpir inmediatamente su uso del Servicio. Si tiene alguna pregunta sobre nuestros Términos, no dude en ponerse en contacto con nosotros en info@optiwe.com
                                </p>
                                <h3>1. Elegibilidad</h3>
                                <p>
                                    Para utilizar el Servicio, debe:
                                </p>
                                <p>
                                    1. tener al menos dieciocho (18) años y estar en capacidad de celebrar contratos;
                                </p>
                                <p>
                                    2. completar el proceso de registro de la cuenta;
                                </p>
                                <p>
                                    3. aceptar estos Términos;
                                </p>
                                <p>
                                    4. proporcionar información de contacto y facturación verdadera, completa y actualizada;
                                </p>
                                <p>
                                    5. no estar basado en Cuba, Irán, Corea del Norte, Siria, o cualquier otro territorio sujeto a un embargo del gobierno de los Estados Unidos, o que haya sido designado por el gobierno de los Estados Unidos como un país "que apoya el terrorismo"; y
                                </p>
                                <p>
                                    6. no estar incluido en ninguna lista del gobierno de los Estados Unidos de personas prohibidas o restringidas.
                                </p>
                                <p>
                                    Al utilizar el Servicio, usted declara y garantiza que cumple con todos los requisitos mencionados anteriormente y que no utilizará el Servicio de manera que viole ninguna ley o regulación. Tenga en cuenta que al hacer una declaración y garantía, está haciendo una promesa legalmente vinculante. Optiwe se reserva el derecho de rechazar el servicio, cerrar cuentas de cualquier Miembro y cambiar los requisitos de elegibilidad en cualquier momento.
                                </p>
                                <h3>2. Término</h3>
                                <p>
                                    Cuando se registra para una cuenta y acepta estos Términos, se forma el Acuerdo entre usted y Optiwe, y el término del Acuerdo (el "Término") comenzará. El Término continuará mientras tenga una cuenta de Optiwe o hasta que usted o nosotros terminemos el Acuerdo de acuerdo con estos Términos, lo que ocurra primero. Si se registra para una cuenta en nombre de una empresa u otra entidad, declara y garantiza que tiene la autoridad para aceptar estos Términos y celebrar el Acuerdo en su nombre.
                                </p>
                                <h3>3. Cambios</h3>
                                <p>
                                    Podemos cambiar cualquiera de los Términos en cualquier momento. A menos que cancele su cuenta, los nuevos Términos serán efectivos inmediatamente después de su publicación y se aplicarán a cualquier uso continuado o nuevo del Servicio.
                                </p>
                                <h3>4. Disputas de cuentas</h3>
                                <p>
                                    Desconocemos el funcionamiento interno de su organización o la naturaleza de sus relaciones personales. No solicitará acceso o información sobre una cuenta que no sea la suya, y resolverá cualquier disputa relacionada con la cuenta directamente con la otra parte. Decidimos quién es el propietario de una cuenta en función de varios factores, incluido el contenido en esa cuenta, y la información de contacto y perfil que se enumera para esa cuenta. En casos en los que exista información de contacto y perfil diferente o no podamos determinar razonablemente la propiedad, le requeriremos que resuelva el asunto a través de canales adecuados fuera de Optiwe.
                                    Cuando se identifica una disputa, podemos suspender cualquier cuenta asociada con la disputa, incluyendo la deshabilitación del inicio de sesión y las capacidades de envío, para proteger la seguridad y privacidad de los datos contenidos en la cuenta hasta que la disputa se resuelva adecuadamente.
                                </p>
                                <h3>5. Pagos</h3>
                                <p>
                                    Mientras sea un Miembro con un Plan Mensual o tenga un saldo pendiente con nosotros, utilizará la plataforma de pagos Stripe (https://stripe.com) y no almacenaremos su información de tarjeta de crédito o débito. Reemplazará la información de cualquier tarjeta que expire con información de una válida. Si su tarjeta es reemplazada automáticamente por una nueva tarjeta por un procesador de pagos, reconoce y acepta que estamos autorizados a deducir cualquier cargo en su cuenta contra la nueva tarjeta. Cualquier persona que utilice una tarjeta declara y garantiza que está autorizada a utilizar esa tarjeta y que cualquier cargo podrá ser facturado en esa tarjeta y no será rechazado. Si no podemos procesar su pedido de tarjeta, intentaremos ponerse en contacto con usted por correo electrónico y podremos suspender su cuenta hasta que se pueda procesar su pago.
                                </p>
                                <h3>6. Cambios en la facturación</h3>
                                <p>
                                    Podemos cambiar nuestras tarifas, incluyendo nuestros cargos por Planes Mensuales, en cualquier momento publicando una nueva estructura de precios en nuestro Sitio de Optiwe o en su cuenta y/o enviándole una notificación por correo electrónico.
                                </p>
                                <h3>7. Comentarios y Derechos Propietarios</h3>
                                <p>
                                    Poseemos todos los derechos de propiedad en el Servicio, incluyendo, entre otros, patentes, marcas comerciales, marcas de servicio, secretos comerciales, derechos de autor y otros derechos de propiedad intelectual. Usted respetará nuestros derechos de propiedad en el Servicio.
                                    Usted declara y garantiza que posee o tiene permiso para utilizar todo el material, contenido, datos e información (incluyendo su información personal y la información personal de otros) que envía a Optiwe en el curso de utilizar el Servicio o que Optiwe recupera o accede a su dirección o con su permiso ("Contenido"). Usted retiene la propiedad del Contenido que carga en el Servicio. Podemos utilizar o divulgar su Contenido (incluyendo cualquier información personal en él) solo según se describe en estos Términos y en nuestra Política de Privacidad.
                                    Usted puede proporcionar o podemos pedirle que proporcione sugerencias, comentarios, opiniones u otros comentarios ("Comentarios") sobre el Servicio. Si nos proporciona algún Comentario, nos otorga una licencia gratuita de regalías, no exclusiva, mundial, sublicenciable y transferible para usar, reproducir, mostrar públicamente, distribuir, modificar y realizar públicamente los Comentarios según lo consideremos conveniente. Cualquier Comentario que elija proporcionar se da completamente de manera voluntaria. Entiende que no recibirá ninguna compensación por sus Comentarios y que podemos utilizar cualquier Comentario que proporcione para mejorar el Servicio o desarrollar nuevas características y servicios.
                                </p>
                                <h3>8. Política de Privacidad</h3>
                                <p>
                                    Su privacidad es importante para nosotros. Lea nuestra Política de Privacidad para obtener información sobre cómo recopilamos, utilizamos y divulgamos su Contenido e información personal y cómo protegemos su privacidad cuando utiliza el Servicio.
                                </p>
                                <h3>9. Cumplimiento de las leyes</h3>
                                <p>
                                    Usted declara y garantiza que su uso del Servicio cumplirá con todas las leyes y regulaciones aplicables. Usted es responsable de determinar si el Servicio es adecuado para usted a la luz de sus obligaciones bajo cualquier regulación como HIPAA, GLB, Leyes de Protección de Datos, leyes y regulaciones de control de exportaciones de los Estados Unidos y leyes y regulaciones de sanciones económicas ("Leyes y Regulaciones de Control de Exportaciones de los Estados Unidos"), u otras leyes aplicables. Si está sujeto a regulaciones (como HIPAA) y utiliza el Servicio, entonces no seremos responsables si el Servicio no cumple con esos requisitos. No puede utilizar el Servicio para actividades ilegales o discriminatorias, incluyendo actos prohibidos por la Ley Federal de Comercio, la Ley de Informes de Crédito Justo, la Ley de Igualdad de Oportunidades de Crédito, la Ley de Protección de la Privacidad en Línea de los Niños u otras leyes aplicables.
                                    Si recopila cualquier información personal relacionada con un menor y almacena dicha información en su cuenta de Optiwe, declara y garantiza que ha obtenido un consentimiento válido para tales actividades de acuerdo con las leyes aplicables de la jurisdicción en la que reside el menor.
                                    Además, si está sujeto a la Ley de Protección de Datos de la UE (según se define en el Anexo de Procesamiento de Datos), reconoce y acepta que tenemos su autorización previa por escrito para responder, a nuestra discreción, a cualquier solicitud de acceso de sujetos de datos que recibamos de sus contactos realizados en virtud de la Ley de Protección de Datos de la UE, o, alternativamente, podemos dirigir cualquier contacto de este tipo a usted para que pueda responder a la solicitud en consecuencia.
                                </p>
                                <h3>10. Controles de Exportación</h3>
                                <p>
                                    El software que respalda la aplicación (el "Software") está sujeto a las Leyes y Reglamentaciones de Control de Exportaciones de los Estados Unidos. Las leyes de exportación son establecidas por el gobierno de los Estados Unidos para evitar que ciertos bienes y servicios lleguen a otros países, generalmente debido a preocupaciones de seguridad o acuerdos comerciales. Ninguno de los Software puede ser descargado o exportado de ninguna otra manera o reexportado en violación de las Leyes y Reglamentaciones de Control de Exportaciones de los Estados Unidos y cualquier otra ley y reglamentación de exportación aplicable (colectivamente, "Leyes de Control de Exportación"). Por lo tanto, usted acepta que no permitirá, directa o indirectamente a través de un tercero, que el Software sea accesible o generado desde dentro, o distribuido o enviado a, ningún país prohibido o embargado mencionado en ninguna Ley de Control de Exportación. Además, certifica que ni usted ni ningún principal, oficial, director o cualquier persona o entidad que sepa que está directamente involucrada en el uso del Servicio está designada en ninguna lista del gobierno de los Estados Unidos de personas prohibidas o restringidas.
                                </p>
                                <h3>11. Limitación de Responsabilidad</h3>
                                <p>
                                    En la medida máxima permitida por la ley, usted reconoce y acepta que (i) asume toda la responsabilidad por cualquier pérdida que resulte de su uso del Servicio, incluidas las descargas desde el Sitio de Optiwe; (ii) nosotros y nuestro Equipo no seremos responsables por ningún daño indirecto, punitivo, especial o consecuente bajo ninguna circunstancia, incluso si se basan en negligencia o se le ha informado de la posibilidad de esos daños; y (iii) en cualquier mes calendario, nuestra responsabilidad total hacia usted que surja en virtud del Acuerdo, ya sea en contrato, agravio (incluida negligencia), incumplimiento del deber legal o de otro tipo, no será superior a lo que nos pagó por el Servicio el mes anterior.
                                    Para evitar cualquier duda, en ningún caso seremos responsables por las pérdidas o daños que sufra si utiliza el Servicio en violación de estos Términos, independientemente de si terminamos o suspendemos su cuenta debido a dicha violación.
                                </p>
                                <h3>12. Sin Garantías</h3>
                                <p>
                                    En la medida máxima permitida por la ley, proporcionamos el Servicio tal cual está. Esto significa que, excepto como se indica expresamente en estos Términos, no proporcionamos garantías, condiciones o compromisos de ningún tipo en relación con el Servicio, ya sea expresa o implícita. Esto incluye, pero no se limita a, garantías de comerciabilidad y aptitud para un propósito particular, que, en la medida máxima permitida por la ley, están excluidas del Acuerdo. Dado que los Miembros utilizan el Servicio por diversas razones, no podemos garantizar que satisfará sus necesidades específicas.
                                </p>
                                <h3>13. Indemnización</h3>
                                <p>
                                    Usted acepta indemnizarnos y eximirnos de responsabilidad a nosotros y a nuestro Equipo por cualquier pérdida, incluidos honorarios legales y gastos que resulten directa o indirectamente de cualquier reclamación que realice que no esté permitida en virtud de estos Términos debido a una "Limitación de Responsabilidad" u otra disposición. (La indemnización es un acuerdo para compensar a alguien por una pérdida). También acepta indemnizarnos y eximirnos de responsabilidad por cualquier pérdida, incluidos honorarios legales y gastos, que resulten directa o indirectamente de (i) su Contenido, (ii) su uso del Servicio, (iii) su violación de cualquier ley o regulación, (iv) reclamaciones de terceros de que usted o alguien que utilice su contraseña hizo algo que, si fuera cierto, violaría cualquiera de estos Términos, (v) cualquier representación falsa hecha por usted o (vi) un incumplimiento de cualquier representación o garantía que haya hecho a nosotros.
                                </p>
                                <h3>14. Alivio Equitativo</h3>
                                <p>
                                    Su violación de estos Términos puede causarnos un daño irreparable a nosotros y a nuestro Equipo. Por lo tanto, tenemos el derecho de buscar alivio injuntivo u otro alivio equitativo si viola estos Términos (lo que significa que podemos solicitar una orden judicial para detenerlo).
                                </p>
                                <h3>15. Descargos de Responsabilidad</h3>
                                <p>
                                    Nosotros y nuestro Equipo no somos responsables del comportamiento de terceros, agencias, sitios web vinculados u otros Miembros, incluyendo aplicaciones, productos o servicios de terceros para su uso en conexión con el Servicio (cada uno, una "Integración de Terceros"). Su uso de cualquier Integración de Terceros y sus derechos con respecto a dicha Integración de Terceros son únicamente entre usted y el tercer partido correspondiente. No somos responsables de la privacidad, seguridad o integridad de ninguna Integración de Terceros ni de las prácticas y políticas de ninguna Integración de Terceros. No hacemos garantías de ningún tipo y no asumimos ninguna responsabilidad de ningún tipo por su uso de cualquier Integración de Terceros.
                                </p>
                                <h3>16. Acuerdo Completo</h3>
                                <p>
                                    Estos Términos a los que ha acordado habilitar constituyen el acuerdo completo entre nosotros en relación con su objeto y sustituyen todos los acuerdos, representaciones y entendimientos anteriores.
                                </p>

                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        </ErrorBoundaryWithLogger>
    )
}
export default PrivacyEs
